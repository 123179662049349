import React from "react"
import PropTypes from "prop-types"
import Footer from "./Footer";
import SmallHeader from "./small-header";
import {Helmet} from "react-helmet";
import FormResponseModel from "./form-response-model";

const Layout = ({ children }) => {
  return (
    <>
        <Helmet>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>
        </Helmet>
        <SmallHeader/>
        <main>
          {children}</main>
        <Footer/>
        <FormResponseModel />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
