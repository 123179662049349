import React from "react"

class FormResponseModel extends React.Component {

    render() {
        return (
            <>
                <div>
                    <div className="modal fade" id="formReponseModel" tabIndex="-1" role="dialog"
                         aria-labelledby={'formReponseModelLabel'} aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-5 text-center">


                                    <div>
                                        <div className="form-group col-md-12">
                                            <p className="fsm-theme-text text-danger error-class">Error : &nbsp; Unable to Submit!</p>
                                        </div>

                                        <div className="text-center success-class" id="form-response">
                                        <svg width="70px" height="70px" className="success" viewBox="0 0 70 70" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g transform="translate(-1233.000000, -901.000000)" stroke="#34D183" strokeWidth="4">
                                                    <g transform="translate(1237.000000, 905.000000)" className="path circle">
                                                        <circle className="path circle" cx="32" cy="32" r="32"> </circle>
                                                        <polyline className="path check"
                                                                  points="48 22.7096774 26.6484279 42.3225806 15.483871 31.5557034">
                                                        </polyline>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2>Thank You!</h2>
                                        <p>The form was submitted successfully.</p>
                                    </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default FormResponseModel
