import React, { Component } from "react"
import images from "./images"

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: "" }
    this.state = { text: "" }
  }

  render() {
    return (
        <footer id="footer" class="footer">
            <div class="bg-color-pink">
                <div class="container mx-auto">
                    <div class="row pt-5">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div>
                                <img src={images.logo}
                                alt="Cosmoally App - Cosmetic Registration Process" 
                                class="img-fluid brand-logo"/><br/>
                                <div class="py-4">
                                    <p class="cosmoally-theme-text color-white text-justify">
                                        Our subject-matter experts guide you through each step of your product’s
                                        launch so you don’t have to worry about the hassle of regulatory approvals.
                                        Our services covers the overall Indian regulatory requirements for imports and manufacturing aspects of cosmetics.
                                    </p>
                                </div>

                                <div class="">
                                    <a href="https://play.google.com/store/apps/details?id=io.cosmoally.app" target="_blank"
                                       class="app-download-link">
                                        <img src={images.GooglePlay} class="img-fluid app-store-images mb-4"
                                         alt="Cosmoally App - Get It On Google Play"/>
                                    </a>
                                    &nbsp;&nbsp;
                                    <a href="https://apps.apple.com/in/app/cosmoally/id1482558525" 
                                    class="app-download-link" target="_blank">
                                        <img src={images.AppleApp} class="img-fluid app-store-images mb-4" 
                                        alt="Cosmoally App - Download on the App Store"/>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-5 col-xs-12 px-4">
                            <div>
                                <h3 class="cosmoally-theme-subheading color-white font-800">Links</h3>
                                <div class="py-4">
                                    <ul class="link_list pl-0">
                                        <li><a href="/#cosmoally-about" class="cosmoally-theme-text color-white">About Us</a></li>
                                        <li><a href="/#cosmoally-features" class="cosmoally-theme-text color-white">Features</a></li>
                                        <li><a href="/privacy-policy/" class="cosmoally-theme-text color-white">Privacy Policy</a></li>
                                        <li><a href="/terms-and-conditions/" class="cosmoally-theme-text color-white">Terms and Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-7 col-xs-12">
                            <div>
                                <h3 class="cosmoally-theme-subheading color-white font-800">Contact us</h3>
                                <div class="pt-4 pb-2">
                                    <ul class="contact_list pl-0">
                                        <li>
                                            <table>
                                                <tr>
                                                    <td class="pr-3">
                                                        <a href="https://goo.gl/maps/knKLrWBiNAZ6XW4y7" target="_blank"
                                                           title="Open in map">
                                                            <img src={images.LocationPin}/>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="https://goo.gl/maps/knKLrWBiNAZ6XW4y7" target="_blank"
                                                           title="Open in map">
                                                            <p class="clini-theme-text color-white">New Delhi Office :<br/>
                                                                CliniExperts Services Pvt. Ltd.<br/>
                                                                Unit No. 325, City Centre Mall, Plot No. 5,<br/>
                                                                Sector 12, Dwarka, New Delhi – 110075</p>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </li>
                                         <li>
                                            <table>
                                                <tr>
                                                    <td class="pr-3">
                                                        <a href="https://goo.gl/maps/giQ3tthr7U64WieNA" target="_blank"
                                                           title="Open in map">
                                                            <img src={images.LocationPin}/>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="https://goo.gl/maps/giQ3tthr7U64WieNA" target="_blank"
                                                           title="Open in map">
                                                            <p class="clini-theme-text color-white">Bengaluru Office :<br/>
                                                                RMZ Galleria, 1st floor,<br/>
                                                                Ambedkar Colony, Yelahanka,<br/>
                                                                Bengaluru, Karnataka – 560064</p>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </li>
                                        <li>
                                            <table>
                                                <tr>
                                                    <td class="pr-3">
                                                        <a href="tel:+917672005050" title="Make Call">
                                                            <img src={images.PhoneCall} />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="tel:+917672005050" title="Make Call">
                                                            <p class="clini-theme-text color-white">Reception:<br/>
                                                                +91 7672005050, +91-11-28081765</p>
                                                        </a>

                                                    </td>
                                                </tr>
                                            </table>
                                        </li>
                                        <li>
                                            <table>
                                                <tr>
                                                    <td class="pr-3">
                                                        <a href="mailto:contact@cliniexperts.com" title="Send e-Mail">
                                                            <img src={images.Email}/>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="mailto:contact@cliniexperts.com" title="Send e-Mail"><p
                                                                class="clini-theme-text color-white">contact@cliniexperts.com</p>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row py-2">
                    <div className="col-6 pr-0 pr-md-3"><small className="color-normal">© Cosmoally.com All Rights Reserved</small></div>
                    <div className="col-6 text-center">

                        <ul className="list-group list-group-horizontal list-style-type-none float-md-right" style={{flexDirection:'row'}}>
                            <li className="list-group-item bg-transparent border-0 p-0 mx-1"><a
                                href="https://www.facebook.com/Cosmoally-100258648284729" target="_blank">
                                <div className="icon-circle border rounded-circle p-1"
                                     style={{height:'30px',width:'30px'}}><i className="fa fa-facebook-f color-normal"> </i>
                                </div>
                            </a></li>
                            <li className="list-group-item bg-transparent border-0 p-0 mx-1"><a
                                href="https://twitter.com/cliniexperts" target="_blank">
                                <div className="icon-circle border rounded-circle p-1"
                                     style={{height:'30px',width:'30px'}}><i className="fa fa-twitter color-normal"> </i></div>
                            </a></li>
                            <li className="list-group-item bg-transparent border-0 p-0 mx-1"><a
                                href="https://www.linkedin.com/company/cliniexperts" target="_blank">
                                <div className="icon-circle border rounded-circle p-1"
                                     style={{height:'30px',width:'30px'}}><i className="fa fa-linkedin color-normal"  style={{position:'relative',top:'-3px'}}> </i></div>
                            </a></li>
                            <li className="list-group-item bg-transparent border-0 p-0 mx-1"><a
                                href="https://www.instagram.com/cosmoally/" target="_blank">
                                <div className="icon-circle border rounded-circle p-1"
                                     style={{height:'30px',width:'30px'}}><i className="fa fa-instagram color-normal" style={{position:'relative',top:'-3px'}}> </i></div>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </footer>
      
    )
  }
}
export default Footer
