import React, { Component } from "react"
import Menu from "../components/Menu"

class SmallHeader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
        <header id="header" className={'header-fixed'} style={{ backgroundColor:'#377dff' }}>
            <Menu/>
        </header>
      )
  }
}

export default SmallHeader
