import React, { Component } from "react"
import images from "./images";

class Menu extends React.Component {

    render() {
        return (
            <div className="container mx-auto">
                <nav className="navbar navbar-expand-md navbar-light px-0">
                    <a className="navbar-brand" href="/">
                        <img src={images.logo} className="header-logo"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <a className="nav-link cosmoally-theme-menu-text color-white"
                                   href="/#cosmoally-about">About <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link cosmoally-theme-menu-text color-white"
                                   href="/#cosmoally-features">Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link cosmoally-theme-menu-text color-white"
                                   href="/#cosmoally-testimonials">Testimonials</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link cosmoally-theme-menu-text color-white"
                                   href="/#cosmoally-faqs">FAQs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link cosmoally-theme-menu-text color-white" href="/blogs/">Blogs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link cosmoally-theme-menu-text color-white"
                                   href="mailto:cosmoally@cliniexperts.com"
                                   title="Send e-Mail to cosmoally@cliniexperts.com"> <i className="fa fa-envelope-o text-white" aria-hidden="true"> </i> &nbsp;cosmoally@cliniexperts.com</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link cosmoally-theme-menu-text link-highlighted bg-color-pink color-white"
                                   href="/#cosmoally-install-app">Install APP
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}
export default Menu
