import logo from "../images/logo.png"
import postImage from "../images/15.jpg"
import blogBanner from "../images/inner.jpg"
import smallLogo from "../images/small-logo.png"

import MobMockUp from "../images/mobile-mockups-home.png"
import GooglePlay from "../images/google-play-badge.png"
import AppleApp from "../images/apple-app-store-badge.png"

import ChevronDown from "../images/chevron-down.svg"
import ChevronUp from "../images/chevron-up.svg"
import MockUp2 from "../images/mockup_2.png"

import ArrLeft from "../images/cosmoally-arrow-left.png"
import ArrRight from "../images/cosmoally-arrow-right.png"
import CELogo from "../images/clini-export-logo.png"
import CosQuote from "../images/cosmoally-quote.png"
import LocationPin from "../images/location-pin.svg"
import PhoneCall from "../images/phone-call.svg"

import MAP_MAKER from "../images/map-marker.svg"
import Phone from "../images/phone.svg"
import Email from "../images/email.svg"
import UserImage from "../images/user-image.png"



const images = {
    logo,
    postImage,
    blogBanner,
    smallLogo,
    MobMockUp,
    GooglePlay,
    AppleApp,
    ChevronDown,
    ChevronUp,
    MockUp2,
    ArrLeft,
    ArrRight,
    CELogo,
    CosQuote,
    LocationPin,
    PhoneCall,
    MAP_MAKER,
    Phone,
    Email,
    UserImage
}
export default images
